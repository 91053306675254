<template>
  <div
    ref="wrapper"
    class="relative h-full"
  >
    <div
      class="md:contents border rounded-md h-full"
      :class="suggestClass"
    >
      <custom-input
        v-bind="inputProps"
        v-model.trim="queryString"
        class="h-full input-wrapper"
        :disabled="disabled"
        :input-class="isChangeMobileInputClass ? ['max-md:border-l-0 max-md:border-r-0 max-md:border-t-0 max-md:focus:ring-0 h-full bg-inherit border-neutral-35 !text-base', {'max-md:border-b-0': isSelectedEmpty}] : ''"
        @focus="isDropdownOpen = true; isFocused = true"
        @blur="isFocused = false"
      >
        <template
          v-if="!isFocused"
          #suffixInner
        >
          <slot
            v-if="isMd"
            name="selected-option"
          />
        </template>
      </custom-input>
      <div>
        <slot
          v-if="!isMd"
          name="selected-option"
        />
      </div>
    </div>
    <custom-card
      v-show="isShowSuggestions"
      class="absolute w-full max-h-[20vh] overflow-y-auto z-10 scrollbar-thin"
      @click="isDropdownOpen = true"
    >
      <template
        v-for="(group, i) of suggestions"
        :key="i"
      >
        <template
          v-for="(options, title) in group"
          :key="title"
        >
          <h4
            v-if="options.length && !isHideHeaders"
            class="text-primary mt-3 mb-2"
          >
            {{ sectionConfigs[title]?.label ?? title }}
          </h4>
          <ol v-if="options.length">
            <li
              v-for="(suggestion, index) of options"
              :key="index"
              class="flex p-2 mb-1 mt-1 ml-2 rounded-md text-primary cursor-pointer"
              :class="{'bg-blue-100': selectedOptions[title]?.includes(suggestion)}"
              @click="toggleOptionSelected(suggestion, title)"
            >
              <slot
                name="option"
                :group="title"
                :option="suggestion"
              >
                {{ getOptionLabel(suggestion, title) }}
              </slot>
            </li>
          </ol>
        </template>
      </template>
      <template v-if="!isFetching">
        <template v-if="isSuggestionsEmpty && queryString?.length !== 0">
          {{ $t('common.noOptions') }}
        </template>
      </template>
      <template v-else>
        <custom-cube-spinner size="sm" />
      </template>
    </custom-card>
  </div>
</template>
<script setup lang="ts">
import CustomInput from '~/ui/inputs/CustomInput.vue'
import CustomCard from '~/ui/cards/CustomCard.vue'
import CustomCubeSpinner from '~/ui/spinners/CustomCubeSpinner.vue'
import Time from '~/common/helpers/time/Time'
import { useAppStateStore } from '~/store/app'

type Option = any
type SelectedOption<T> = {
  option: T,
  group?: string,
}

const props = defineProps({
  query: {
    type: String,
    default: '',
  },
  selected: {
    type: Object as PropType<Record<string, Option[]>>,
    default: () => ({}),
  },
  suggestions: {
    type: Array as PropType<Array<Record<string, Option[]>>>,
    default: () => [],
  },
  sectionConfigs: {
    type: Object as PropType<Record<string, {label?: string, optionLabel?: string}>>,
    default: () => ({}),
  },
  inputProps: {
    type: Object,
    default: () => {},
  },
  multiple: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  closeOnSelect: {
    type: Boolean,
    default: false,
  },
  isFetching: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'sm',
  },
  isHideHeaders: {
    type: Boolean,
    default: false,
  },
  suggestClass: {
    type: [String, Object, Array],
    default: null,
  },
  isChangeMobileInputClass: {
    type: Boolean,
    default: true,
  },
})
const emits = defineEmits<{(e: 'update:query', value: string): void,
  (e: 'update:selected', value: Array<SelectedOption<any>>): void,
  (e: 'select', option: Option, group: string): void,
  (e: 'deselect', option: Option, group: string): void
}>()

const isDropdownOpen = ref(false)
const isFocused = ref(false)
const wrapper = ref<HTMLElement | null>(null)
const emitDebounced = Time.debounce((value: string) => {
  emits('update:query', value)
})

const queryString = customRef((track, trigger) => ({
  get() {
    return props.query
  },
  set: emitDebounced,
}))

const selectedOptions = customRef((track, trigger) => ({
  get() {
    return props.selected
  },
  set: value => {
    emits('update:selected', value)
  },
}))

const isShowSuggestions = computed(() => isDropdownOpen.value && queryString.value?.length !== 0)
const isSuggestionsEmpty = computed(() => (props.suggestions as Array<Record<string, Option[]>>)
  .every(group => Object.values(group)
    .every(options => options.length === 0)))
const isSelectedEmpty = computed(() => Object.values(props.selected)
  .every(options => !options.length))

const toggleOptionSelected = (option: Option, optionsGroup: string) => {
  const options: Option[] = selectedOptions.value?.[optionsGroup] ?? []
  if (!options?.includes(option)) {
    options?.push(option)
    emits('select', option, optionsGroup)
  } else {
    options.splice(options.indexOf(option), 1)
    emits('deselect', option, optionsGroup)
  }
}

const getOptionLabel = (option: Option, sectionTitle: string) => (props.sectionConfigs[sectionTitle]?.optionLabel ? option[props.sectionConfigs[sectionTitle].optionLabel as string] : option)

const handleClickOutside = (click: MouseEvent) => {
  const targetElement = click.target as HTMLElement
  const wrapperElement = wrapper.value as HTMLElement
  if (!wrapperElement.contains(targetElement) || !wrapperElement.contains(targetElement.closest('.input-wrapper'))) {
    isDropdownOpen.value = false
  }
}

const isMd = computed(() => useAppStateStore().breakpoints.isMd)

onMounted(() => {
  document.body.addEventListener('click', handleClickOutside)
})
onBeforeUnmount(() => {
  document.body.removeEventListener('click', handleClickOutside)
})
</script>
